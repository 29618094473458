import api from "./interceptor";
import {xAuth,baseUrl,service} from "../utils/constant";
import axios from "axios";


const error={"code":400,"message":"Internet not connected on your device."};
const token = localStorage.getItem('token');
const headers = {
    'Content-Type': 'application/json',
    'x-auth': xAuth
  }

export const sub = async (userData) => {
    try {       
        const response = await axios.post(`${baseUrl}/user/sub`, userData,
        {headers:headers});
        return response;
    } catch (e) {
        if(e.response!=null && e.response!=undefined) throw setResponse(e);
        else throw error;
    }
};
export const sublba = async (userData) => {
    try {
       
        const response = await axios.post(`${baseUrl}/user/sublba`, userData,
        {headers:headers});
        return response;
    } catch (e) {
        if(e.response!=null && e.response!=undefined) throw setResponse(e);
        else throw error;
    }
};


export const subsl = async (userData) => {
    try {
       
        const response = await axios.post(`${baseUrl}/user/subsl`, userData,
        {headers:headers});
        return response;
    } catch (e) {
        if(e.response!=null && e.response!=undefined) throw setResponse(e);
        else throw error;
    }
};

export const unsub = async (userData) => {
    try {
        headers.Authorization='Bearer '+token;       
       if(service=="lbwa") //botswana
        {
            const response = await axios.post(`${baseUrl}/user/sub`, userData,
            {headers:headers});
            return response;
        }
        else if(service=="lba") //liberia
        {
            const response = await axios.post(`${baseUrl}/user/sublba`, userData,
            {headers:headers});
            return response;
        }
        else if(service=="sl") //SIERRALEONE
        {
            const response = await axios.post(`${baseUrl}/user/subsl`, userData,
            {headers:headers});
            return response;
        }
    } catch (e) {        
        if(e.response!=null && e.response!=undefined) throw setResponse(e);
        else throw error;
    }
};


export const subApi = async (userData) => {
    try {
        headers.Authorization='Bearer '+token;   
        let finalUrl=baseUrl;   
        if(service==="ocm")finalUrl=finalUrl+'/user/orange'; 
        else if(service==="mtnz")finalUrl=finalUrl+'/user/mtn';
        else if(service==="ogb")finalUrl=finalUrl+'/user/ogb';
        else if(service==="lbwa")finalUrl=finalUrl+'/user/sub';
        else if(service==="lba")finalUrl=finalUrl+'/user/sublba';
        else if(service==="sl")finalUrl=finalUrl+'/user/subsl';

        const response = await axios.post(`${finalUrl}`, userData,
        {headers:headers});
        return response;
    } catch (e) {        
        if(e.response!=null && e.response!=undefined) throw setResponse(e);
        else throw error;
    }
};

export const unsubApi = async (userData) => {
    try {
        headers.Authorization='Bearer '+token;   
        let finalUrl=baseUrl;   
        if(service==="ocm")finalUrl=finalUrl+'/user/orange'; 
        else if(service==="mtnz")finalUrl=finalUrl+'/user/mtn';
        else if(service==="ogb")finalUrl=finalUrl+'/user/ogb';
        else if(service==="lbwa")finalUrl=finalUrl+'/user/sub';
        else if(service==="lba")finalUrl=finalUrl+'/user/sublba';
        else if(service==="sl")finalUrl=finalUrl+'/user/subsl';

        const response = await axios.post(`${finalUrl}`, userData,
        {headers:headers});
        return response;
    } catch (e) {        
        if(e.response!=null && e.response!==undefined) throw setResponse(e);
        else throw error;
    }
};

export const getRanking = async () => {
    try {
        headers.Authorization='Bearer '+token;
        const response = await axios.post(`${baseUrl}/user/list_user_time`,'',
            { headers: headers})
        return response;
    } catch (e) {
        if(e.response!=null && e.response!==undefined) throw e.response;
        else throw error;
    }
}

export const checkStatus = async () => {
    try {
        headers.Authorization='Bearer '+token;
        const response = await axios.get(`${baseUrl}/user/checkStatus`,
            { headers: headers})
        return response;
    } catch (e) {
        if(e.response!=null && e.response!==undefined) throw e.response;
        else throw error;
    }
}

function setResponse(e)
{   
    //console.log("e="+JSON.stringify(e.response));
    if(e.response.status==401){
        localStorage.clear();
        window.location.href ="/";
    }
    else {return e.response.data;}
}
