import React, { useState, useEffect } from 'react'
import '../css/style.css';
import '../css/login1.css';
import { NavLink } from 'react-router-dom';
import ReactCustomFlagSelect from 'react-custom-flag-select';
import "react-custom-flag-select/lib/react-custom-flag-select.min.css";
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import {subApi} from '../../services/user-api';
import {serviceId,Loader,countryCode,flag} from "../../utils/constant";

const Lba = () => {
    const [loading, setLoading] = useState(false);
    const [flowLoading, setFlowLoading] = useState(false); 
    const navigate = useNavigate();
    const [exist, setExist] = useState(false);
    const [otp, setOtp] = useState('');
    const [plan, setPlan] = useState(3);
    const [ButtonDisabled, setButtonDisabled] = useState(false);
    const [phone, setPhone] = useState(localStorage.getItem("ugAlias"));
    const [message, setMessage] = useState({message: "",error: false});
    const [flow, setFlow] = useState('');
    const [otpId, setOtpId] = useState('');

    const find = (arr, obj) => {
        return arr.filter(o => Object.keys(obj).every(i => obj[i] === o[i]));
      };

      const cancel = async() => {
        setMessage({message: "",error: false});
        setFlow(false);
      }
      const handleRadioChange = async(input) =>{
        setPlan(input);
      } 

    const handleNumInputsChange = (numInputs) => {
        setOtp(numInputs);
        setButtonDisabled(numInputs.length !== 4 || isNaN(numInputs));
      };

      const gotToHome = async() => {
        navigate("/home");
      };
    const handleButtonClick = async() => {
        console.log(phone);
        setMessage({message: "",error: false});
        if(phone.length>=8)
        {
            localStorage.setItem("ugAlias",phone);  
            if(phone.substring(0,1)==="0")  setPhone(phone.substring(1));       
            if(phone.length<10)setPhone(countryCode+phone)
            setFlow("CONFIRM");
        }
        else if(phone.length<=0){setMessage({message: "Please enter a number",error: true})}
        else{setMessage({message: "Pleas enter a valid number",error: true})}
      };
    const sendOtp = async() => {
        //console.log('phone='+phone);
        setMessage({message: "",error: false});
        if(phone!==undefined && phone!=null && phone.length>=8)
        {
            var nm=phone;
            if(nm.substring(0,1)=="0")nm=nm.substring(1);    
            if(nm.length<10)nm=countryCode+nm;        

            setLoading(true);
            await subApi({action:3,serviceId:serviceId,ani: nm}).then((res) => {
                setFlow("OTPCONFIRM");  
            }).catch((err) => {
                console.log(err);
                setMessage({message: err.message, error: true });
            }).finally(() => setLoading(false))
        }
        else if(phone!==undefined || phone!=null || phone.length<=0){setMessage({message: "Please enter a number",error: true})}
        else{setMessage({message: "Please enter a valid number",error: true})}
      };

           
    const confirmOtp = async() => {
        setMessage({message: "",error: false});   
            if(otp!=null && otp.length===4)
            {
                var nm=phone;
                if(nm.substring(0,1)=="0")nm=nm.substring(1);
                if(nm.length<10)nm=countryCode+nm
                setLoading(true);
                await subApi({action:4,serviceId:serviceId,ani: nm,otpId:otpId,otp:otp}).then((res) => { 
                        if(res.data.responseCode===202) // already subscribed )
                        {
                            localStorage.setItem("token", res.data.data.accessToken);
                            setExist(true);setFlow("SUCCESS");                 
                        }  
                        else 
                        { 
                            localStorage.setItem("ugAlias",nm);
                            setFlow("PACK");                      
                        } 
                }).catch((err) => {
                    setMessage({message: err.message, error: true});
                }).finally(() => setLoading(false))
            }
            else{ setMessage({ message: "OTP is not valid",error: true });}
      }
      
      
    const confirmSub = async() => {
        console.log(phone);
        setMessage({message: "",error: false});
        if(phone.length>=8)
        {
            if(phone.length<10)setPhone(countryCode+phone);
            setLoading(true);
            await subApi({action:1,serviceId:serviceId,planId:plan,ani:phone,mode:'WAP'}).then((res) => {
                localStorage.setItem("token", res.data.data.accessToken);
                setFlow("SUCCESS");
            }).catch((err) => {
                console.log(err)
                setMessage({message: err.message,error: true})
            }).finally(() => setLoading(false))
        }
        else if(phone.length<=0){setMessage({message: "Phone enter a number",error: true})}
        else{setMessage({message: "Phone enter a valid number",error: true})}
      };

      useEffect(() => {
        console.log("phone="+phone);
        setMessage({message: "",error: false});        
        if (phone) {
            setFlowLoading(true); 
            subApi({action:0,ani: phone,serviceId:serviceId}).then((res) => {
                if(res.data.data!==null && res.data.data!==undefined)
                {
                    setFlowLoading(false);
                    localStorage.setItem("token", res.data.data.accessToken);
                    window.location.href ="/home";
                }
                else
                {
                    setFlowLoading(false);
                    setFlow("OTP");//
                }
            }).catch((err) => {
                console.log(err);
                setFlowLoading(false);
                setFlow("OTP");
            });
        }           
    }, []);
    return (  
        flowLoading? <Loader />
        :    
        <div className='home'>
            <div className="maine-div">
                <div className="container-fluid box-wrapper login"> 
                    <div className='pt-5'>
                        <div className="login-thumbnail">
                            <img src="/images/uv_games_logo.png" alt="UVGames" />
                        </div>
                    </div>
                    <div className="login-header pb-4">
                        <div className="box-wrapper mx-auto text-center position-relative"> 
                            <span className="modal-level-title game-title">Welcome to UVGames!</span>
                        </div>
                    </div>
                    <div className="login-info">
                        <div className="login-thumbnail2">
                            <img src="/images/login_img.png" alt="UVGames" />
                        </div>
                    {flow==="SUCCESS"?(<>
                        {exist===true?<h3 className="login-title-green mb-3 mt-3 text-center">You are already subscribed on service.</h3>
                        : <h3 className="login-title-green mb-3 mt-3 text-center">You have successfully subscribed the service.</h3>}
                            
                        <h3 className="login-title mb-3 mt-3 text-center">To access the service, click on below "Click Here" button.</h3>
                        {loading ? <Loader />:<button className="btn btn-primary btn-pm-custom" type="button" onClick={gotToHome}>Click Here</button> } 
                    </>                     
                    ):flow==="CONFIRM"?(
                            <div className="text-center phone-info">
                                <h3 className="header-title mb-3 mt-3 text-center">Confirm your subscription</h3> 
                                {message.message && <div className="mb-3"><p className={`alert alert-${message.error ? 'danger' : 'success'}`}>{message.message}</p> </div>}  
                                {loading ? <Loader />:<button className="btn btn-primary btn-pm-custom" type="button" onClick={confirmSub}>Confirm</button>}
                                <div className="resend_otp">
                                    <a className="send_otp_link" onClick={cancel}> &lt;&lt;&nbsp;Back</a>
                                 </div>
                            </div>
                    ):flow==="PACK"?(
                    <>
                        <h3 className="login-title mb-3 mt-3 text-center">Find new games based on new technology which are full with fun, entertainment and knowledge.</h3>
                      
                        <h3 className="header-title mb-3 mt-3 text-center">Select your subscription plan</h3>  
                        <div className="text-center radio">
                            <label>
                                <input type="radio" id="rd1" value="3" checked={plan === 3} onChange={() => handleRadioChange(3)} />
                                0.07$/Day
                            </label>
                            <label>
                                <input type="radio" id="rd2" value="4" checked={plan === 4} onChange={() => handleRadioChange(4)} />
                                0.25$/Week
                            </label>
                        </div>
                        <div className="text-center phone-info"> 
                                {message.message && <div className="mb-3"><p className={`alert alert-${message.error ? 'danger' : 'success'}`}>{message.message}</p> </div>}        
                                {loading ? <Loader />:<button className="btn btn-primary btn-pm-custom" type="button" onClick={handleButtonClick}>Subscribe</button>}
                        </div>
                    </>
                    ):flow==="OTPCONFIRM"?( 
                        <>
                            <h3 className="login-title mb-3 mt-3 text-center">Enter OTP to confirm your number</h3>
                                                        
                            <div className="phone-info">

                                <div className="otp_section">               
                                    <OtpInput
                                        value={otp}
                                        numInputs={4}
                                        renderInput={(props, index) => (
                                            <input
                                                {...props}
                                                type="tel"  // Use type="tel" to display the numeric keypad on mobile devices
                                                inputMode="numeric"  // Specify the numeric input mode
                                            />
                                        )}
                                        inputStyle="form-control form-otp"
                                        onChange={handleNumInputsChange}
                                        />
                                </div>
                                {
                                    message.message && <div className="mb-3">
                                    <p className={`alert alert-${message.error ? 'danger' : 'success'}`}>{message.message}</p>
                                    </div>
                                }    
                                {loading ? <Loader />:<button className="btn btn-primary btn-pm-custom" type="button" onClick={confirmOtp} disabled={ButtonDisabled}>Confirm</button>}
                                <div className="resend_otp">
                                        <a className="send_otp_link" onClick={cancel}> &lt;&lt;&nbsp;Back</a>
                                    </div>
                                
                            </div>
                        </>
                    ):( // OTP send flow
                       <>
                            <h3 className="login-title mb-3 mt-3 text-center">Enter your Number</h3>
                            
                            <div className="phone-input">
                                <div className="has-validation input-group">                            
                                    <div className="dialing-code">
                                        <img src={"https://flagcdn.com/"+flag+".svg"} width="24" alt="Cameroon" />
                                        <span>+{countryCode}</span>
                                    </div>
                                    <input                                    
                                        className="form-control form-mobile-no" 
                                        type="number" 
                                        inputMode="numeric" 
                                        value={phone?phone:''}
                                        onChange={(event) => {setPhone(event.target.value);}}
                                        placeholder="Enter Mobile Number"
                                    />                                                 
                                </div>                                  
                                {
                                    message.message && <div className="mb-3">
                                    <p className={`alert alert-${message.error ? 'danger' : 'success'}`}>{message.message}</p>
                                    </div>
                                }
                            
                                {loading ? <Loader />:<button className="btn btn-primary btn-pm-custom" type="button" onClick={sendOtp} disabled={ButtonDisabled}>Submit</button>}
                                
                            </div>
                        </>  
                    )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Lba